<template>
  <v-row align="center" justify="center" no-gutters class="overflow-x-auto">
    <vue-headful
      title="Automotriz Petersen - Terminos"
      description="Terminos de la pagina de Automotriz Petersen"
    />
    <v-col cols="12" :xs="12" :sm="12" :md="6" :lg="6" :xl="4">
      <v-card class="my-3">
        <v-card-title>Condiciones de servicio</v-card-title>
        <v-card-text>
          1. <strong>Aceptación de las Condiciones:</strong> Al utilizar nuestra
          aplicación, el usuario acepta cumplir con estas Condiciones de
          Servicio. Si no está de acuerdo con alguna de las condiciones, no debe
          utilizar esta aplicación. <br />
          2. <strong>Servicios Ofrecidos:</strong> A través de nuestra
          aplicación, AUTOMOTRIZ PETERSEN envía mensajes informativos
          relacionados con la calidad de servicio, promociones e información
          sobre servicios contratados. <br />3. <strong>Registro:</strong> Para
          utilizar nuestra aplicación, el usuario debe registrarse
          proporcionando información personal válida y actualizada. Es
          responsabilidad del usuario mantener la seguridad de su cuenta.
          <br />4. <strong>Uso Adecuado:</strong> El usuario se compromete a
          utilizar la aplicación de manera adecuada y legal, sin enviar mensajes
          ofensivos, difamatorios o que infrinjan los derechos de terceros.
          <br />5. <strong>Limitación de Responsabilidad:</strong> AUTOMOTRIZ
          PETERSEN no será responsable de ningún daño o pérdida resultante del
          uso o la imposibilidad de usar la aplicación. <br />6.
          <strong>Cambios en las Condiciones:</strong>
          AUTOMOTRIZ PETERSEN se reserva el derecho de modificar estas
          Condiciones de Servicio en cualquier momento. Si se realizan cambios
          significativos, se notificará a los usuarios a través de la aplicación
          o por otros medios.<br />
          7. <strong>Terminación:</strong> AUTOMOTRIZ PETERSEN puede suspender o
          terminar el acceso de un usuario a la aplicación en caso de
          incumplimiento de estas condiciones. <br />8.
          <strong>Contacto:</strong> Para preguntas o inquietudes relacionadas
          con esta política de privacidad, por favor contacta a
          <strong @click="mail" class="text--primary" style="cursor: pointer">
            ventas@automotrizpetersen.cl
          </strong>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {},
  data: () => ({}),
  computed: {},
  watch: {},
  methods: {
    mail() {
      window.location.href = `mailto:ventas@automotrizpetersen.cl`;
    },
  },
};
</script>
<style></style>
